
  import { IonGrid, IonCol, IonRow, IonIcon, IonRippleEffect } from '@ionic/vue';
  import { ComponentOptions, computed, onMounted, ref } from 'vue';
  import { backspaceOutline } from 'ionicons/icons';
  import { navigateLang } from '../main'
  import { useI18n } from 'vue-i18n';
  export default {
    name: 'NumpadComponent',
    props: {
      beacons: {
        required: true
      },
      tourID: {
        required: true
      },
      workNumber: {
        default: ''
      }
    },
    components: {
      IonGrid, IonCol, IonRow, IonIcon, IonRippleEffect
    },
    emits: ["wrok-choosed"],
    setup(props: ComponentOptions, context: any) {
      const number = ref('');
      const validChars = ref([{}]);

      const selectedPage = computed(() => {
        return props.beacons.find((b: any) => b.Number.toString() === number.value)
      })

      const pageTitle = computed(() => {
        const b = props.beacons.find((b: any) => b.Number.toString() === number.value)
        if (b) {
          const title = b.Title.replace(new RegExp('^' + number.value + ' '), '')
          return title
        }
        return ''
      })

      const index = computed(() => {
        return props.beacons.findIndex((b: any) => b.Number.toString() === number.value)
      })      

      const updateValidChars = () =>  {
        const position = number.value.length
        const valid: any = [];
        for (const beacon of props.beacons) {
          if (beacon.Number >= position && beacon.Number.toString().substr(0, position) === number.value) {
            
            valid[beacon.Number.toString().charAt(position)] = 0
          }
        }
        validChars.value = Object.keys(valid)
      }

      const onNumClick = (num: number) =>  {
        number.value = number.value + num
        updateValidChars()
        if (Object.keys(props.beacons).includes(number.value)) {
          // setTimeout(() => this.$refs.enter.focus(), 100)
        }
      }

      const onDelClick = () =>  {
        if (number.value.length > 0) {
          number.value = number.value.slice(0, -1)
          updateValidChars()
        }
      }

      const isNumEnabled = (num: number) => {
        return (validChars.value.indexOf(num) >= 0)
      }

      const onSelectPage =  () => {
        if (selectedPage.value) {
          context.emit('wrok-choosed', index.value);
          navigateLang('/page/' + props.tourID + '/' + selectedPage.value.ID)
        }
      }

      onMounted(() => {
        updateValidChars();
      })

      const { t } = useI18n()

      return {
        number,
        pageTitle,
        onNumClick,
        onDelClick,
        isNumEnabled,
        selectedPage,
        onSelectPage,
        backspaceOutline,
        t
      }
    },
    methods: {
      getWorkTitle(title: string) {
      if (title.indexOf(',') > -1) {
        const data = title.split(',')

        switch (data.length) {
          case 3:
            return '<span>' + data[0] + '</span>, <span style="font-style:italic;">' + data[1] + '</span>, ' + '<span>' +  data[2] + '</span>'
          case 2:
            return '<span>' + data[0] + '</span>, <span style="font-style:italic;">' + data[1] + '</span>'
          case 1:
            return '<span>' + data[0] + '</span>'
        }
      } else {
        return title
      }
    }
    }
  }
