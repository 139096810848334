import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-036ef5d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { fullscreen: true }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.pageData.Sections, (section, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "section"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.entries, (entry, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(entry.Class + 'Entry'), {
                  entryData: entry,
                  mainImage: (entry.Class === 'Image' && index === 0)
                }, null, 8, ["entryData", "mainImage"]))
              ]))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}