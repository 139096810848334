
import { IonApp, IonContent, IonRouterOutlet, IonSpinner } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from './store';
import { navigateLang } from './main'
import { useI18n } from 'vue-i18n';
import UserService from './services/UserService';
import { User } from './types';
import BaseService from './services/BaseService';
import AppMenu from "./components/AppMenu.vue";
const App = defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonRouterOutlet, 
    AppMenu,
    IonSpinner
  },
  setup() {
    const store = useStore()
    const selectedIndex = ref(0);
    const isReady = ref(false);
    const route = useRoute();
    const { locale } = useI18n()

    onMounted(async () => {
      try {
        await hadelLang()
        await store.getAppConfig()

        const storageUser: User = await UserService.getUserFromStorage();
        if (storageUser) {
          const user = await UserService.validate()
          await UserService.setUserInStorage(user)
          await store.setUser(user);
        }
        isReady.value = true;
      } catch(e) {
        isReady.value = true;
      }
    })

    const hadelLang = async () => {
      const lang = await BaseService.getFromStorage('lang')
      if (lang) {
        if(route.params.lang && lang !== route.params.lang) {
          locale.value = route.params.lang.toString();
          document.documentElement.lang = route.params.lang.toString();
        } else {
          document.documentElement.lang = lang;
        }
      } else {
        if(route.params.lang && locale.value !== route.params.lang) {
          locale.value = route.params.lang.toString();
          document.documentElement.lang = route.params.lang.toString();
        } else {
          document.documentElement.lang = "de";
        }
      }
    }

    return { 
      selectedIndex,
      isReady,
      route,
      navigateLang,
    }
  }
});

export default App;
