
import { IonContent, IonGrid, IonRow, IonCol } from '@ionic/vue';
import TextEntry from '../entries/TextEntry.vue';
import ImageEntry from '../entries/ImageEntry.vue';


export default {
  name: 'SimplePage',
  components: {
    IonContent,
    TextEntry,
    ImageEntry,
    IonGrid, IonRow, IonCol
  },
  props: {
    pageData: {
      type: Object,
      required: true
    }
  },
  setup () {
    return {
    }
  }
}
