
  import { IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonContent, IonPage } from '@ionic/vue';
  import { ref } from 'vue';
  import { BaseUrl } from '../util/vars';
  import { useStore } from '@/store';
  import { useI18n } from 'vue-i18n';
  export default {
    name: 'VideoPlayer',
    props: { 
      video: {
        type: Object
      },
      stroke: {
        type: Number,
        required: true
      },
      radius: {
        type: Number,
        required: true
      },
      workId: {
        type: Number
      },
      subtitles: {
        type: Object,
        required: false
      },
      disable: {
        type: Boolean,
        default: false
      }
    },
    /*emits: [
      "play-state-changed"
    ],*/
    components: { 
      IonModal,
      IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonContent, IonPage
    },
    setup(props: any) {
      const normalizedRadius = props.radius - props.stroke * 2;
      const circumference = normalizedRadius * 2 * Math.PI;
      const { locale } = useI18n();
      const store = useStore();
      const { t } = useI18n()

      const isVideoModalOpen = ref(false);
      const setVideoModalOpen = async (state: boolean) => {
        isVideoModalOpen.value = state;
        if (store.state.isLoggedIn.value) {
          await store.updateVisitedWorksIds(props.workId, locale.value)
        }
      }

      const onError = () => {
        console.log('Video: Error! Something went wrong');
      };

      const onCanPlay = (event: any) => {
        console.log('Video can play');
        event.target.play();
      };
      
      const onEnded = () => {
        console.log('Video ended');
      };

      const onPlay = () => {
        console.log('Video play');
      };

      const onPause = () => {
        console.log('Video paused');
      };

      const modalDidPresented = () => {
        const unkownEl = document.querySelector('.sc-ion-modal-md[tabindex="0"]')
        const backdrop = document.querySelector('ion-backdrop')
        if (unkownEl) {
          unkownEl.setAttribute('aria-hidden', 'true')
        }
        if (backdrop) {
          backdrop.setAttribute('aria-hidden', 'true')
        }
      }

      return {
        setVideoModalOpen,
        isVideoModalOpen,
        normalizedRadius,
        circumference,
        BaseUrl,
        onError,
        onCanPlay,
        onEnded,
        onPlay,
        onPause,
        t,
        modalDidPresented
      };
    }
  }
