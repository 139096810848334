
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonMenuButton,
  // IonBadge,
  useIonRouter,
  IonIcon
} from "@ionic/vue";
import { navigateLang } from "@/main";
import { useStore } from '@/store';
import { User } from '@/types';
import { useI18n } from 'vue-i18n';
import { computed } from '@vue/runtime-core';
import { arrowBack } from "ionicons/icons";
import { useRouter } from 'vue-router';

export default {
  name: "AppHeader",
  props: {
    showGoBack: {
      type: Boolean,
      default: false
    },
    showHome: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Mein Art Surfer"
    },
    showAppLogo: {
      type: Boolean,
      default: true
    },
    logoName: {
      type: String,
      default: 'app_logo.svg'
    },
    showAccountIcon: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    solidHeaderBgOnScroll: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonMenuButton,
    // IonBadge,
    IonIcon
  },
  setup() {
    const store = useStore()
    const isLoggedIn = store.state.isLoggedIn
    const user = store.state.user
    const { locale } = useI18n();
    const ionRouter = useIonRouter()
    const router = useRouter()
    const langSelectionPage = store.state.langSelectionPage
    
    const getUserLetters = (user: User) => {
      if (user?.firstName && user?.lastName) {
        const firstNameFirstLetter = user.firstName.charAt(0).toUpperCase()
        const lastNameFirstLetter = user.lastName.charAt(0).toUpperCase()
        return ''+firstNameFirstLetter+''+lastNameFirstLetter +''
      }
      return "--"
    }

    const getVisitedWorksIdsCount = computed(() => {
      if (locale.value == 'en') {
        if (user.value?.visitedWorksIds) {
          if (user.value?.visitedWorksIds.en) {
            return user.value.visitedWorksIds.en.length
          }
        }
      }
      if (locale.value == 'de') {
        if (user.value?.visitedWorksIds) {
          if (user.value?.visitedWorksIds.de) {
            return user.value.visitedWorksIds.de.length
          }
        }
      }
      
      return 0
    })

    const goBack = () => {
      if (ionRouter.canGoBack()) {
        return router.back()
      } else {
        return navigateLang('/page/'+langSelectionPage.value?.NextPageID)
      }
    }

    const navigateMeinArtsurfer = (pageName: string) => {
      window.open('mein-artsurfer/#/' + pageName, '_self')
    }

    return {
      navigateLang,
      isLoggedIn,
      user,
      getUserLetters,
      locale,
      getVisitedWorksIdsCount,
      arrowBack,
      goBack,
      langSelectionPage, navigateMeinArtsurfer
    };
  },
};
