import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slider_component = _resolveComponent("slider-component")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { fullscreen: true }, {
    default: _withCtx(() => [
      _createVNode(_component_slider_component, {
        data: $props.pageData.Tours,
        title: $props.pageData.Title,
        includeQuiz: $props.pageData.IncludeQuiz
      }, null, 8, ["data", "title", "includeQuiz"])
    ]),
    _: 1
  }))
}