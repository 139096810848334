
import { IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonRippleEffect, IonText } from '@ionic/vue';
import { BaseUrl } from '@/util/vars';
import { navigateLang } from '../../main'
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { grid } from 'ionicons/icons';
import { ComponentOptions, computed } from '@vue/runtime-core';
import HTMLIonContentElement from '@ionic/core';
import { useStore } from '@/store';

export default {
  name: 'TourSelectionPage',
  components: {
    IonContent,
    IonText,
    IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle,
    IonRippleEffect
  },
  props: {
    pageData: {
      type: Object,
      required: true
    }
  },
  emits: ["on-content-scroll"],
  setup (props: ComponentOptions, context: any) {
    const router = useRouter()
    const { t, locale } = useI18n()
    const store = useStore()
    const showArtMatch = store.state.appConfig.value?.Settings?.ShowArtMatch
    const showMeinPinnwand = store.state.appConfig.value?.Settings?.ShowMeinPinnwand

    const loadImage = (url: string, thumb: string) => {
      var img = new Image()
      img.onload = function() {
        var imgEl = document.querySelector<HTMLIonContentElement>('.content-img')
        var background = 'url('+ img.src +') no-repeat center/cover fixed'
        if(imgEl) imgEl.style.setProperty('--background', background)
      }
      img.src = BaseUrl + 'xpedeo/' + url
      return '--background: url('+ thumb +') no-repeat center/cover fixed;'
    }

    const isMainTourSelectionPage = () => {
      const myArtSurferPage = props.pageData.TourTypes.find((item: any) => item.ID === store.state.appConfig.value?.Settings?.MyArtSurferPageID)
      if (typeof myArtSurferPage !== 'undefined' && myArtSurferPage) {
        return true
      }
      return false
    }

    const isLangGerman = computed(() => {
      return locale.value === 'de'
    })

    
    const onScroll = (event: any) => {
      if (event.detail.currentY === 0 ) {
          context.emit('on-content-scroll', false);
      } else {
        context.emit('on-content-scroll', true)
      }
    }

    const goToArtMartch = () => {
      location.href = '/artmatch/index.html'
    }

    const goToMeinPinnwand = () => {
      location.href = '/pinnwandmuseum/index.html'
    }

    return {
      BaseUrl,
      loadImage,
      navigateLang,
      t,
      router,
      grid,
      onScroll,
      showArtMatch,
      showMeinPinnwand,
      isMainTourSelectionPage,
      goToArtMartch,
      goToMeinPinnwand,
      isLangGerman
    }
  }
}
