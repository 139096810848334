import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_menu = _resolveComponent("app-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      (_ctx.isReady)
        ? (_openBlock(), _createBlock(_component_app_menu, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.isReady)
        ? (_openBlock(), _createBlock(_component_ion_router_outlet, {
            key: 1,
            id: "main"
          }))
        : _createCommentVNode("", true),
      (!_ctx.isReady)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 2 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_spinner)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}