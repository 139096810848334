
import { IonContent } from '@ionic/vue';
import SliderComponent from '../SliderComponent.vue';

export default {
  name: 'TourTypePage',
  components: {
    IonContent,
    SliderComponent
  },
  props: {
    pageData: {
      type: Object,
      required: true
    }
  },
  setup () {
    return {
    }
  }
}
