import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { App } from '@capacitor/app';
import { navigateLang } from '../main'
import PagePage from '../views/PagePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import ('../views/SplashPage.vue')
  },
  {
    path: '/:lang?/errors',
    component: () => import ('../views/ErrorsPage.vue')
  },
  {
    path: '/:lang?/page/:id/:workId?',
    component: PagePage,
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

App.addListener('appUrlOpen', function (data: any) {
  const slug = data.url.split('#').pop();

  // We only push to the route if there is a slug present
  if (slug) {
    console.log('deep link catched: ', slug)
    navigateLang(slug)
  }
});

export default router
