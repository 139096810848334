
import { IonContent, IonPage, IonGrid, IonRow, IonCol, useIonRouter, useBackButton, IonSpinner } from '@ionic/vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { ref, computed, watchEffect, onMounted } from 'vue';
import { useStore } from '@/store';
import TextEntry from '../components/entries/TextEntry.vue';
import ImageEntry from '../components/entries/ImageEntry.vue';
import TourSelectionPage from '@/components/pages/TourSelectionPage.vue';
import TourTypePage from '@/components/pages/TourTypePage.vue';
import TourPage from '@/components/pages/TourPage.vue';
// import ChildrenTourPage from '@/components/pages/ChildrenTourPage.vue';
import SimplePage from '@/components/pages/SimplePage.vue';
import { App } from '@capacitor/app';
import XpSelectLanguagePage from '@/components/pages/XpSelectLanguagePage.vue'
import AppHeader from '@/components/AppHeader.vue';

export default {
  name: 'PagePage',
  components: {
    IonContent,
    IonPage,
    TextEntry,
    ImageEntry,
    IonGrid, IonRow, IonCol,
    TourSelectionPage, TourTypePage, TourPage,
    SimplePage,
    XpSelectLanguagePage,
    AppHeader,
    IonSpinner
  },
  setup() {
    const route = useRoute();
    const isReady = ref(false);
    const store = useStore()
    const page = store.state.page;
    const i = useIonRouter()

    const pageComponent = computed(() => {
      if(!page.value) {
        return false
      } 
      switch(page.value.Class) {
        case 'MenuItem':
          return 'SimplePage'
        case '':
          return 'SimplePage'
        default:
          return page.value.Class + 'Page'
      }
    })

    const routeId = ref('')
    watchEffect(() => {
      if(route.params.id !== routeId.value) {
        console.log('in')
        if(!route.params.workId) {
          if (route.params.id) {
            isReady.value = false;
            store.getPageData(parseInt(route.params.id.toString())).then(()=> {
              setTimeout(() => {
                isReady.value = true;
                routeId.value = route.params.id.toString()
              }, 500);
            });
          }
        }
        else {
          if(pageComponent.value === 'MyArtSurfer') {
            isReady.value = false;
            store.getPageData(parseInt(route.params.id.toString())).then(()=> {
              setTimeout(() => {
                isReady.value = true;
              }, 500);
            });
          }
          
          if(!pageComponent.value) {
            isReady.value = false;
            store.getPageData(parseInt(route.params.id.toString())).then(()=> {
              setTimeout(() => {
                isReady.value = true;
              }, 500);
            });
          }

          if(pageComponent.value === 'SimplePage') {
            isReady.value = false;
            store.getPageData(parseInt(route.params.id.toString())).then(()=> {
              setTimeout(() => {
                isReady.value = true;
              }, 500);
            });
          }

          setTimeout(() => {
            isReady.value = true;
            routeId.value = route.params.id.toString()
          }, 500);
        }
      }
    })


    const headerCanGoBack = computed(() => {
      return page.value && (page.value.Class !== 'TourType' && page.value.Class !== 'TourSelection' && page.value.Class !== 'XpSelectLanguage')
    })
    const headerShowHome = computed(() => {
      return page.value && (page.value.Class === 'TourSelection' || page.value.Class === 'TourType')
    })
    const headerShowAppLogo = computed(() => {
      return page.value && (page.value.Class === 'TourSelection' || page.value.Class === 'XpSelectLanguage')
    })
    const headerTitle = computed(() => {
      return page.value && page.value.Title && (page.value.Class !== 'TourSelection' && page.value.Class !== 'XpSelectLanguage') ? page.value.Title : null
    })
    const headerIsTransparent = computed(() => {
      return page.value && (page.value.Class === 'TourSelection' || page.value.Class === 'TourType' || page.value.Class === 'ChildrenTour' || page.value.Class === 'Tour')
    })
    const headerBgOnScroll = computed(() => {
      return !!scrolledDown.value
    })

    const ionRouter = useIonRouter();
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        console.log('cant go back, must exit')
        App.exitApp();
      }
    });

    const scrolledDown = ref(false)
    const onContentScroll = (value: boolean) =>{
      if (value) {
        scrolledDown.value = true
      } else {
        scrolledDown.value = false
      }
    }

    return {
      router: useRouter(),
      pageComponent,
      isReady,
      page,
      headerCanGoBack,
      headerTitle,
      headerIsTransparent,
      headerShowHome,
      headerShowAppLogo,
      onContentScroll,
      headerBgOnScroll
    }
  }
}
