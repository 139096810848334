import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      ($setup.isReady)
        ? (_openBlock(), _createBlock(_component_app_header, {
            key: 0,
            "solid-header-bg-on-scroll": $setup.headerBgOnScroll,
            showGoBack: !!$setup.headerCanGoBack,
            "show-home": !!$setup.headerShowHome,
            showTitle: !$setup.headerShowAppLogo,
            title: $setup.page?.Title,
            showAppLogo: !!$setup.headerShowAppLogo,
            logoName: 'app_logo.svg',
            transparent: !!$setup.headerIsTransparent
          }, null, 8, ["solid-header-bg-on-scroll", "showGoBack", "show-home", "showTitle", "title", "showAppLogo", "logoName", "transparent"]))
        : _createCommentVNode("", true),
      ($setup.isReady)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.pageComponent), {
            key: 1,
            onOnContentScroll: $setup.onContentScroll,
            pageData: $setup.page
          }, null, 40, ["onOnContentScroll", "pageData"]))
        : (_openBlock(), _createBlock(_component_ion_content, {
            key: 2,
            fullscreen: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_spinner)
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}