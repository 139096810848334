
import { IonContent, modalController, IonFooter, IonSlides, IonSlide, IonRow, IonCol, IonButton, IonIcon } from '@ionic/vue';
import { defineComponent} from 'vue';
import { BaseUrl } from '../util/vars';
import { ref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import { remove, add, close, search } from 'ionicons/icons';
import HTMLIonSlidesElement from '@ionic/core';
import { ComponentPublicInstance } from '@vue/runtime-core';

export default defineComponent({
  name: 'ImageZoomModal',
  props: {
    img: {
      type: Object,
      required: true
    }
  },
  setup() {
    const closeModal = () => {
      modalController.dismiss();
    }

    const imgRef = ref<ComponentPublicInstance<HTMLImageElement>>()

    const sliderOpts = {
      zoom: true
    };

    const loadImage = (url: string, thumb: string) => {
      //'background: url('+ BaseUrl + 'xpedeo/' + item.Image.Url +') no-repeat center/cover;'
      var img = new Image()
      img.onload = function() {
        var imgEl = imgRef.value
        if(imgEl)
          imgEl.src = img.src
      }
      img.src = BaseUrl + 'xpedeo/' + url
      return thumb
    }

    const { t } = useI18n()

    const slidesDidLoad = (event: any) => {
      event.srcElement.update()
    }

    return {
      BaseUrl,
      closeModal,
      sliderOpts,
      t,
      remove, add, close,
      loadImage,
      imgRef,
      slidesDidLoad,
      search
    }
  },
  methods: {
    async zoom(zoomIn: boolean) {
      const slides = document.querySelector('ion-slides#modal-slides') as HTMLIonSlidesElement
      const swiper = await slides.getSwiper()
      console.log(swiper)
      const zoom = swiper.zoom;
      zoomIn ? zoom.in() : zoom.out();
    }
  },
  components: { IonContent, IonFooter, IonSlides, IonSlide, IonRow, IonCol, IonButton, IonIcon }
});
