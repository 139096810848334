import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withKeys as _withKeys, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-250f58e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["height", "width", "aria-label"]
const _hoisted_4 = ["stroke", "stroke-opacity", "stroke-dasharray", "stroke-width", "r", "cx", "cy"]
const _hoisted_5 = ["stroke-dasharray", "stroke-width", "r", "cx", "cy"]
const _hoisted_6 = {
  x: "0",
  y: "0",
  width: "120",
  height: "120"
}
const _hoisted_7 = {
  key: 1,
  class: "text-shadow audio-time",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.audio?.Url)
      ? (_openBlock(), _createElementBlock("audio", {
          key: 0,
          id: 'main-audio-' + $props.slideIndex
        }, [
          _createElementVNode("source", {
            src: $setup.BaseUrl + 'xpedeo/' + $props.audio.Url,
            type: "audio/mpeg"
          }, null, 8, _hoisted_2)
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_openBlock(), _createElementBlock("svg", {
      tabindex: 1,
      height: $props.radius * 2,
      width: $props.radius * 2,
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.isStillPlaying ? $setup.stopAudio() : $setup.playAudio())),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => ($setup.isStillPlaying ? $setup.stopAudio() : $setup.playAudio()), ["enter"])),
      class: _normalizeClass(["svg-btn", {disable: $props.disable}]),
      "aria-label": $setup.isStillPlaying ? _ctx.$t('message.stopAudio') : _ctx.$t('message.playAudio'),
      role: "button"
    }, [
      _createElementVNode("circle", {
        stroke: $setup.isStillPlaying ? '#989898' : 'white',
        "stroke-opacity": $setup.isStillPlaying ? 0.7 : 1,
        fill: "transparent",
        "stroke-dasharray": $setup.circumference + ' ' + $setup.circumference,
        "stroke-width": $props.stroke,
        r: $setup.normalizedRadius,
        cx: $props.radius,
        cy: $props.radius
      }, null, 8, _hoisted_4),
      _createElementVNode("circle", {
        stroke: "white",
        fill: "transparent",
        "stroke-dasharray": $setup.circumference + ' ' + $setup.circumference,
        style: _normalizeStyle({ strokeDashoffset: $setup.strokeDashoffset }),
        "stroke-width": $props.stroke,
        r: $setup.normalizedRadius,
        cx: $props.radius,
        cy: $props.radius
      }, null, 12, _hoisted_5),
      (_openBlock(), _createElementBlock("foreignObject", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass(["play", {paused: $setup.isStillPlaying, disable: !!$props.disable}])
        }, null, 2)
      ]))
    ], 42, _hoisted_3)),
    ($setup.isStillPlaying)
      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($setup.leftTime), 1))
      : _createCommentVNode("", true)
  ], 64))
}