
  import { IonSlides, IonSlide, IonText, IonButton } from '@ionic/vue';
  import { BaseUrl } from '../util/vars';
  import { computed, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import HTMLIonSlideElement from '@ionic/core';
  import { navigateLang } from '../main';
  import HTMLCollectionOf from "typeScript";
  import { useI18n } from 'vue-i18n';
  
  export default {
    name: 'SliderComponent',
    props: {
      data: {
        type: Object,
        required: true
      },
      title: {
        type: String
      },
      includeQuiz: {
        type: Boolean
      }
    },
    components: {
      IonSlides,
      IonSlide,
      IonText,
      IonButton
    },
    setup(props: any) {
      const slideOpts = {
        pagination: {
          el: '.swiper-pagination',
        },
      };
      
      const isBeginning = ref(true)
      const isEnd = ref(false)

      const updateNavigation = async (event: any) => {
        const slider = event.srcElement;
        const activeSlider = slider.querySelector('.swiper-slide-active');
        isEnd.value = await slider.isEnd();
        isBeginning.value = await slider.isBeginning();
        updateStartBtnTabIndex(activeSlider)
      }

      const updateStartBtnTabIndex = (activeSlider: HTMLElement) => {
        activeSlider.focus()
        var allSlides = document.getElementsByTagName("ion-slide") as HTMLCollectionOf<HTMLElement>
        for (let slide of allSlides) {
          // slide.tabIndex = -1
          slide.setAttribute("aria-hidden", "true") 
        }
        // activeSlider.tabIndex = 0
        activeSlider.setAttribute("aria-hidden", "false") 

        var startBtns = document.getElementsByClassName("start") as HTMLCollectionOf<HTMLElement>
        for (let startBtn of startBtns) {
          startBtn.tabIndex = -1
          startBtn.setAttribute("aria-hidden", "true") 
        }
        const activeStartBtns = activeSlider.querySelector('.start') as HTMLElement
        if(activeStartBtns) {
          activeStartBtns.tabIndex = 0
          activeStartBtns.setAttribute("aria-hidden", "false") 
        }
      }

      const initSilder = (event: any) => {
        const slider = event.srcElement;
        console.log(slider)
        const activeSlider = slider.querySelector('.swiper-slide-active');
        updateStartBtnTabIndex(activeSlider);
      }

      const prevSlide = () => {
        document.querySelector('ion-slides')?.slidePrev();
      }

      const nextSlide = () => {
        document.querySelector('ion-slides')?.slideNext();
      }

      const singleSlide = computed(() => {
        return props.data.length === 1
      })

      watch(() => isEnd, newVal => {
        console.log('watchEnd', newVal);
      })

      const loadImage = (url: string, thumb: string, index: number, imageImportantArea: string) => {
        //'background: url('+ BaseUrl + 'xpedeo/' + item.Image.Url +') no-repeat center/cover;'
        const ImageImportantArea = imageImportantArea ? imageImportantArea : 'center'
        var img = new Image()
        img.onload = function() {
          var imgEl = document.querySelector<HTMLIonSlideElement>('.slide-image-' + index)
          var background = 'url('+ img.src +') no-repeat '+ImageImportantArea+'/cover'
          if(imgEl) imgEl.style.background = background
        }
        img.src = BaseUrl + 'xpedeo/' + url
        return 'background: url('+ thumb +') no-repeat '+ImageImportantArea+'/cover;'
      }

      const getCleanTitle = (title: string) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = title;
        return tmp.textContent || tmp.innerText || "";
      }

      const { t } = useI18n()

      return {
        BaseUrl,
        slideOpts,
        isEnd,
        isBeginning,
        updateNavigation,
        initSilder,
        prevSlide,
        nextSlide,
        singleSlide,
        router: useRouter(),
        loadImage,
        navigateLang,
        t,
        getCleanTitle
      }
    }
  }
