/**
 * @see https://dev.to/3vilarthas/vuex-typescript-m4j
 */
import { inject } from 'vue';
import { state, State } from './state';
import { Page, User } from '@/types';
import UserService from '@/services/UserService';
import BaseService from '@/services/BaseService';

type StoreType = {
  state: State;
  getAppConfig: () => Promise<any>;
  getPageData: (id: number) => Promise<any>;
  getWorkPageData: (id: number) => Promise<any>;
  setUser: (user: User) => Promise<any>;
  updateUser: (user: User) => Promise<any>;
  updateVisitedWorksIds: (id: number, lang: string) => Promise<any>;
}

export const storeSymbol = Symbol('store');
export const createStore = () => {

  const getAppConfig = async () => {
    const data: Page = await BaseService.getAppConfig()
    state.appConfig.value = data
    if (data?.NextPageID) {
      const splashPageData: Page = await BaseService.getPage(data?.NextPageID)
      if(splashPageData) {
        state.splashPage.value = splashPageData
        const langSelectionPage: Page = await BaseService.getPage(splashPageData.NextPageID)
        state.langSelectionPage.value = langSelectionPage
      }
    }
  }

  const getPageData = async (id: number) => {
    const data: Page = await BaseService.getPage(id)
    state.page.value = data
  }

  const getWorkPageData = async (id: number) => {
    const data: Page = await BaseService.getPage(id)
    state.workPage.value = data
  }

  const setUser = async (user: User) => {
    state.user.value = user
    state.isLoggedIn.value = user ? true : false
  }

  const updateUser = async (user: User) => {
    await UserService.setUserInStorage(user)
    state.user.value = user
  }

  const updateVisitedWorksIds = async (id: number, lang: string) => {
   const user = await UserService.updateVisitedWorksIds(id, lang)
   updateUser(user)
  }

  return {
    state: state,
    getAppConfig,
    getPageData,
    getWorkPageData,
    setUser,
    updateUser,
    updateVisitedWorksIds,
  }
}

export const useStore = () =>  {
  return inject(storeSymbol) as StoreType
}

export type Store = StoreType
