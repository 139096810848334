
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
export default {
  name: "TextEntry",
  props: {
    entryData: {
      type: Object,
    },
  },
  components: {
    IonGrid, IonRow, IonCol
  },
};
