
import { ComponentPublicInstance } from '@vue/runtime-core';
import { ref } from '@vue/reactivity';
import { BaseUrl } from '../../util/vars';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
  export default {
    name: 'ImageEntry',
    props: {
      entryData: {
        type: Object
      },
      mainImage: {
        type: Boolean,
        requier: true,
        default: false
      }
    },
    components: {
      IonGrid, IonRow, IonCol
    },
    setup() {
      const imageRef = ref<ComponentPublicInstance<HTMLImageElement>>()
      const loadImage = (url: string, thumb: string) => {
        var img = new Image()
        
        img.onload = function() {
          var imgEl = imageRef.value
          if(imgEl)
            imgEl.src = BaseUrl + 'xpedeo/' + url
        }
        img.src = BaseUrl + 'xpedeo/' + url
        return thumb
      }
      
      return {
        imageRef,
        loadImage
      }
    }
  }
