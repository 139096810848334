import { User } from "@/types";
import axios from "axios";
import BaseService from "./BaseService";

export default {
	async login(email: string, password: string | null) {
		const response = await axios.post(`/user/login?XDEBUG_SESSION_START=PHPSTORM`, { email: email, password: password })
		const user: User = response.data
		return user
	},

	async register(email: string, password: string) {
		const response = await axios.post(`/user/register`, { email: email, password: password})
		const isregistred: boolean = response.data
		return isregistred
	},

	async activate(activationKey: string) {
		const response = await axios.post(`/user/activate?XDEBUG_SESSION_START=PHPSTORM`, { activationKey: activationKey })
		const user: User = response.data
		return user
	},

	async sendResetpasswordEmail(email: string) {
		const response = await axios.post(`/user/send-reset-password-email`, { email: email })
		const isSent: boolean = response.data
		return isSent
	},

	async checkResetPasswordKey(key: string) {
		const response = await axios.post(`/user/check-reset-password-Key`, { key: key })
		const isValid: boolean = response.data
		return isValid
	},

	async resetPassword(key: string, newPassword: string) {
		const response = await axios.post(`/user/reset-password`, {key: key, newPassword: newPassword })
		const ispasswordChanged: boolean = response.data
		return ispasswordChanged
	},

	async resendActivationLink(email: string) {
		const response = await axios.post(`/user/resend-activation-link?XDEBUG_SESSION_START=PHPSTORM`, { email: email })
		const isSent: boolean = response.data
		return isSent
	},

	async updateVisitedWorksIds(id: number, lang: string) {
		const response = await axios.post(`/user/update-visited-works-ids`, { lang: lang, id: id })
		const user: User = response.data
		return user
	},

	async updateUser(params: object) {
		const response = await axios.post(`/user/update-user?XDEBUG_SESSION_START=PHPSTORM`, { user: params })
		const user: User = response.data
		return user
	},

	async validate() {
		const response = await axios.post(`/user/validate`)
		const user: User = response.data
		return user
	},

	async changePassword(password: string, newPassword: string) {
		const response = await axios.post(`/user/change-password`, {password: password, newPassword: newPassword})
		const isChanged: boolean = response.data
		return isChanged
	},

	async changeEmail(email: string) {
		const response = await axios.post(`/user/change-password`, {email: email})
		const isChanged: boolean = response.data
		return isChanged
	},

	async deleteAccount() {
		const response = await axios.post(`/user/delete?XDEBUG_SESSION_START=PHPSTORM`)
		const isDeleted: boolean = response.data
		return isDeleted
	},

	async setUserInStorage(user: User) {
		await BaseService.setObjectInStorage( 'user', user);
	},

	async getUserFromStorage() {
		return await BaseService.getFromStorage('user' )
	},


	/*
	** to be used when no possibilty to use store i.e: in router->index.ts and main.ts
	*/
	async isLoggedIn() {
		const user = await this.getUserFromStorage()
		if (user) {
			return !!user.authKey
		} else {
			return false
		}
	}
}