import { Page } from "@/types";
import axios from "axios";
import { Preferences } from '@capacitor/preferences';
import { getCurrentLangLocale } from "@/main";

export default {
	getAxiosLangInstance () {
		const lang = getCurrentLangLocale() ? getCurrentLangLocale() + '/' : ''
		const axiosInstance = axios.create({
			baseURL: process.env.VUE_APP_BASE_URL + process.env.VUE_APP_XPEDEO + lang + process.env.VUE_APP_API_URL
		})
		return axiosInstance
	},

	async getAppConfig () {
		const axiosInstance = this.getAxiosLangInstance()
		const response = await axiosInstance.get(`/pages/37`)
		const appConfig: Page = response.data
		return appConfig
	},

	async getPage (id: number) {
		const axiosInstance = this.getAxiosLangInstance()
		const response = await axiosInstance.get(`/pages/${id}`)
		const page: Page = response.data
		return page
	},

	async setObjectInStorage(key: string, data: any) {
		Preferences.set({
			key: key,
			value: JSON.stringify(data),
		});
	},

	async getFromStorage(key: string) {
		const { value } = await Preferences.get({ key: key })
		if (value) {
			return JSON.parse(value)
		}
		else
			return null
	}
}