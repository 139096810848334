
import {
  IonIcon,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonContent,
  menuController,
  IonButton,
  IonItem,
  IonLabel
} from "@ionic/vue";
import { arrowForwardOutline } from "ionicons/icons";
import { useStore } from '@/store';
import { useRoute } from 'vue-router';
import { navigateLang } from "@/main";
import UserService from '@/services/UserService';
import BaseService from '@/services/BaseService';

export default {
  name: "AppMenu",
  components: {
    IonIcon,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonContent,
    IonButton,
    IonItem,
    IonLabel
  },
  setup() {
    const store = useStore()
    const appConfig = store.state.appConfig
    const route = useRoute()
    const appSettings = store.state.appConfig.value?.Settings
    const isLoggedIn = store.state.isLoggedIn
    const langSelectionPage = store.state.langSelectionPage
    const splashPage = store.state.splashPage
    const showLangSelection = store.state.appConfig.value?.Settings?.ShowLangSelection

    const navigateMenuItem = (id: number | undefined, type: string, externalRedirectUrl: string | undefined) => {
      if (type === 'Redirect' && externalRedirectUrl) {
        window.open(externalRedirectUrl, '_blank')
      } else if (type === 'artsurferStartPage') {
        window.open('/', '_self')
      } else if (type === 'register') {
        window.open('mein-artsurfer/#/register', '_self')
      } else {
        navigateLang('/page/'+id)
      }
    }

    const logout = async () => {
      await UserService.setUserInStorage(null);
      await store.setUser(null);
      await BaseService.setObjectInStorage('questionnaireData', null)
      await BaseService.setObjectInStorage('matches', null)
      await BaseService.setObjectInStorage('favorit-matches', null)
      store.state.isLoggedIn.value = false
      window.open('/#/page/' + store.state.appConfig.value?.Settings?.ArtsurferLanguagePageID, '_self')
    }
    
    return {
      menuController,
      appConfig,
      arrowForwardOutline,
      route,
      navigateMenuItem,
      appSettings,
      navigateLang,
      isLoggedIn,
      logout, 
      splashPage,
      langSelectionPage,
      showLangSelection
    };
  },
};
