
import { IonContent, IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel} from '@ionic/vue';
import { ComponentOptions, onBeforeMount } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { navigateLang } from '@/main';

export default {
  name: 'XpSelectLanguagePage',
  components: {
    IonContent,
    IonGrid, IonRow, IonCol,
    IonList, IonItem, IonLabel
  },
  props: {
    pageData: {
      type: Object,
      required: true
    }
  },
  setup (props: ComponentOptions) {
    const { locale } = useI18n();
    const store = useStore()
    const langSelectionPage = store.state.langSelectionPage

    onBeforeMount(() => {
      const store = useStore()
      const showLangSelection = store.state.appConfig.value?.Settings?.ShowLangSelection
      if(!showLangSelection) {
        navigateLang('/page/' + props.pageData.NextPageID)
      }
    })

    const setLang = async (langCode: string) => {
      locale.value = langCode.substring(0, langCode.indexOf('_'));
      document.documentElement.lang = langCode.substring(0, langCode.indexOf('_'));
      await store.getAppConfig()
      navigateLang('/page/'+langSelectionPage.value?.NextPageID)
    }
   
    return {
      setLang
    }
  }
}
