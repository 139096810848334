import { Page, User } from "@/types";
import { Ref, ref } from "vue";

type StateType = {
  splashPage: Ref<Page>;
  page: Ref<Page>;
  workPage: Ref<Page>;
  langSelectionPage: Ref<Page>;
  appConfig: Ref<Page>;
  user: Ref<User>;
  isLoggedIn: Ref<boolean>;
  myArtSurferStartPage: Ref<Page>
  currentTours: Ref<Page>;
  currentpageId: Ref<number>;
  currentWorkPageId: Ref<number>;
}

export const state: StateType = {
  splashPage: ref(null),
  page: ref(null),
  workPage: ref(null),
  langSelectionPage: ref(null),
  appConfig: ref(null),
  user: ref(null),
  isLoggedIn: ref(false),
  myArtSurferStartPage: ref(null),
  currentTours: ref(null),
  currentpageId: ref(0),
  currentWorkPageId: ref(0)
}

export type State = StateType