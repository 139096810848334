
  import { computed, onMounted, ref, watchEffect } from 'vue';
  import { BaseUrl } from '../util/vars';
  import { useStore } from '@/store';
  import { useI18n } from 'vue-i18n';
  
  export default {
    name: 'AudioPlayer',
    props: { 
      audio: {
        type: Object
      },
      stroke: {
        type: Number,
        required: true
      },
      radius: {
        type: Number,
        required: true
      },
      workId: {
        type: Number,
      },
      slideIndex: {
        type: Number,
      },
      disable: {
        type: Boolean,
        default: false
      }
    },
    emits: ["play-state-changed", "audio-finished"],
    setup(props: any, context: any) {
      const currentTime = ref(0);
      let duration: any = ref(0);
      let progress = ref(0);
      const isStillPlaying = ref(false);
      const normalizedRadius = props.radius - props.stroke * 2;
      const circumference = normalizedRadius * 2 * Math.PI;
      const { t } = useI18n()
      const { locale } = useI18n();
      const store = useStore();
    
      const playAudio = async () => {
        if (store.state.isLoggedIn.value) {
          await store.updateVisitedWorksIds(props.workId, locale.value)
        }
        
        const audio: HTMLAudioElement | null = document.querySelector('#main-audio-' + props.slideIndex);
        audio?.play();
        audio?.addEventListener('timeupdate', function () {
          currentTime.value = parseInt(audio?.currentTime.toFixed());
        })
        audio?.addEventListener('play', () => {
          isStillPlaying.value = true
        })
        context.emit('play-state-changed', true);
        context.emit("audio-finished", false, props.workId)

        audio?.addEventListener('ended', () => {
          isStillPlaying.value = false;
          context.emit('play-state-changed', false);
          context.emit("audio-finished", true, props.workId)
        })
      }

      const stopAudio = () => {
        const audio: HTMLAudioElement | null = document.querySelector('#main-audio-' + props.slideIndex);
        audio?.pause();
        isStillPlaying.value = false;
        context.emit('play-state-changed', false);
      }

      const strokeDashoffset = computed(() => {
        let value = circumference - Math.floor(progress.value) / 100 * circumference;
        if(Math.floor(value) == 0) {
          return 0
        }
        return value;
      })

      const leftTime = computed(() => {
        let leftTimeAsSeconds =  duration.value - currentTime.value;
        let minutes: string | number = Math.floor(leftTimeAsSeconds / 60);
        // minutes = (minutes >= 10) ? minutes : "0" + minutes; enable this line if u want to add 0 before the minutes when its under 9 minutes
        minutes = (minutes <= 0) ? 0 : minutes;
        let seconds: string | number = Math.floor(leftTimeAsSeconds % 60);
        seconds = (seconds >= 10) ? seconds : "0" + seconds;
        Math.floor(leftTimeAsSeconds) <= 0 ? seconds = "00" : seconds
        return minutes + ":" + seconds;
      });

      onMounted(() => {
        const audio: HTMLAudioElement | null = document.querySelector('#main-audio-' + props.slideIndex);
        audio?.addEventListener('canplaythrough', () => {
          duration.value = audio?.duration;
        })
        isStillPlaying.value = false;
      })

      watchEffect(() => {
        if(currentTime.value > 0) {
          progress.value = currentTime.value  * 100 / duration.value;
        }
      })

      return {
        progress,
        normalizedRadius,
        circumference,
        strokeDashoffset,
        BaseUrl,
        playAudio,
        leftTime,
        isStillPlaying,
        stopAudio,
        t
      };
    }
  }
