import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d6d2069"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["height", "width"]
const _hoisted_2 = ["stroke-opacity", "stroke-dasharray", "stroke-width", "r", "cx", "cy"]
const _hoisted_3 = {
  x: "0",
  y: "0",
  width: "120",
  height: "120"
}
const _hoisted_4 = {
  class: "play",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!

  return (_openBlock(), _createElementBlock("svg", {
    height: $props.radius * 2,
    width: $props.radius * 2
  }, [
    _createElementVNode("circle", {
      stroke: "white",
      "stroke-opacity": 0.7,
      fill: "transparent",
      "stroke-dasharray": $setup.circumference + ' ' + $setup.circumference,
      "stroke-width": $props.stroke,
      r: $setup.normalizedRadius,
      cx: $props.radius,
      cy: $props.radius
    }, null, 8, _hoisted_2),
    (_openBlock(), _createElementBlock("foreignObject", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ion_spinner, { color: "white" })
      ])
    ]))
  ], 8, _hoisted_1))
}