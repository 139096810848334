
  import { IonSpinner } from '@ionic/vue';

  export default {
    name: 'AudioPlayerPlaceHolder',
    components: {
      IonSpinner
    },
    props: { 
      stroke: {
        type: Number,
        required: true
      },
      radius: {
        type: Number,
        required: true
      },
    },
    setup(props: any) {
      const normalizedRadius = props.radius - props.stroke * 2;
      const circumference = normalizedRadius * 2 * Math.PI;

      return {
        normalizedRadius,
        circumference,
      };
    }
  }
