import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19a8205a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "swiper-zoom-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_ion_slides = _resolveComponent("ion-slides")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_slides, {
          id: "modal-slides",
          ref: "slidesRef",
          options: _ctx.sliderOpts,
          onIonSlidesDidLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.slidesDidLoad($event)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_slide, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("img", {
                    ref: "imgRef",
                    class: "full-width",
                    src: _ctx.loadImage(_ctx.img.Url, _ctx.img.Thumb)
                  }, null, 8, _hoisted_2)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["options"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, {
              size: "3",
              class: "ion-text-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.zoom(false))),
                  fill: "clear",
                  color: "white"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.search,
                      slot: "start"
                    }, null, 8, ["icon"]),
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.remove,
                      slot: "start"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_col, {
              size: "6",
              class: "ion-text-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeModal())),
                  fill: "clear",
                  color: "white"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.close,
                      slot: "start"
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.close')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_col, {
              size: "3",
              class: "ion-text-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.zoom(true))),
                  fill: "clear",
                  color: "white"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.search,
                      slot: "start"
                    }, null, 8, ["icon"]),
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.add,
                      slot: "start"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}