import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createStore, storeSymbol } from './store'

import { IonicVue } from '@ionic/vue';
import { createI18n } from 'vue-i18n'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import './style/style.css'

/* Theme variables */
import './theme/variables.css';
import UserService from './services/UserService';
import axios from 'axios';
import { state } from './store/state';
import { User } from './types';
import BasicHelpers from './helpers/BasicHelpers';

console.log("env: " + process.env.NODE_ENV + ' ,base url: '+ process.env.VUE_APP_BASE_URL);

const messages = {
  'de': require('./messages/de.json'),
  'en': require('./messages/en.json'),
  'easy': require('./messages/easy.json'),
  'ru': require('./messages/ru.json'),
  'ar': require('./messages/ar.json'),
  'fr': require('./messages/fr.json'),
}

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  legacy: false,
  messages,
})

// setting the api url
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL + process.env.VUE_APP_XPEDEO + process.env.VUE_APP_API_URL

// multilang navigate
export const navigateLang = (route: string, replace = false) => {
  if(replace) {
    if(i18n.global.locale.value !== 'de') {
      router.replace('/' + i18n.global.locale.value + route)
    } else {
      router.replace(route)
    }
  } else {
    if(i18n.global.locale.value !== 'de') {
      router.push('/' + i18n.global.locale.value + route)
    } else {
      router.push(route)
    }
  }
}

export const getCurrentLangLocale = () => {
  if(i18n.global.locale.value !== 'de') {
    return i18n.global.locale.value
  } else {
    return ''
  }
}

/*
** Add a request interceptor
** here we add the Authorization header only for loged in user
*/
axios.interceptors.request.use(async function (config) {
  const user: User = await UserService.getUserFromStorage()
  if (user?.authKey) {
    if (config.headers) {
      
      config.headers['Authorization'] = [
        'Bearer', user?.authKey
      ].join(' ') 
    }
  }
  else {
    if (config.headers) {
      delete config.headers['Authorization']
    }
  }
  return config
}, function (error) {
  return Promise.reject(error);
});

/*
** Add a response interceptor
** here we check the response code and notify the user with ionic toast
*/
axios.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  if (error.response && error.response.status === 422) {
    await BasicHelpers.presentToast(error.response.data.message, undefined, 'warning')
  }
  if (error.response && error.response.status === 401) {
    UserService.setUserInStorage(null)
    state.user.value = null
    state.isLoggedIn.value = false
    window.open('mein-artsurfer/#/login', '_self')
  }
  if (error.response && error.response.status === 404) {
    await BasicHelpers.presentToast('The requested page was not found', undefined, 'warning')
  }
  if (error.response && error.response.status === 409) {
    await BasicHelpers.presentToast(error.response.data.message, undefined, 'warning')
  }
  if (error.response && error.response.status === 500) {
    await BasicHelpers.presentToast('Internal server error.', undefined, 'warning')
  }
  if (error.response && error.response.status > 500) {
    // might occur: 502 - Bad Gateway, 503 - Service Unavailable
    await BasicHelpers.presentToast('Server-side error + ' + error.response.status + ': ' + error.response.data, undefined, 'warning')
  }
  return Promise.reject(error)
});

console.log('App-Lang: ', i18n.global.locale.value)

const app = createApp(App)
  .use(IonicVue, {
    rippleEffect: true,
    mode: 'md'
  })
  .use(router)
  .use(i18n)
  .provide(storeSymbol, createStore());
  
router.isReady().then(() => {
  app.mount('#app');
});