import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08989e91"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return ($props.mainImage)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        ref: "imageRef",
        class: "main-image",
        src: $setup.loadImage($props.entryData.Image.Url, $props.entryData.Image.Thumb),
        alt: $props.entryData.Image.Alt
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createBlock(_component_ion_grid, {
        key: 1,
        class: "not-main-image-container"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                "size-xs": "10",
                "size-sm": "6",
                "size-ms": "4",
                "size-lg": "4",
                "size-xl": "3"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    ref: "imageRef",
                    src: $setup.loadImage($props.entryData.Image.Url, $props.entryData.Image.Thumb),
                    alt: $props.entryData.Image.Alt
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}